import type { FC, HTMLAttributes, MouseEvent, PropsWithChildren } from "react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { getWindow } from "../utils/window";

export type ModalProps = HTMLAttributes<HTMLDivElement> & {
  open?: boolean;
  opacity?: 0 | 10 | 20 | 25 | 30 | 40 | 50 | 60 | 70 | 75 | 80 | 90 | 100;
  hideBackdrop?: boolean;
  disableBackdropClose?: boolean;
  onClose?: (event?: MouseEvent<HTMLDivElement>) => void;
  onBackdropClick?: (event?: MouseEvent<HTMLDivElement>) => void;
  "data-testid"?: string;
};

// @ts-expect-error
const Modal: FC<PropsWithChildren<ModalProps>> = (props) => {
  const {
    open,
    children,
    hideBackdrop,
    disableBackdropClose = false,
    onBackdropClick,
    onClose,
    className = "",
    opacity = 50,
  } = props;
  const [ready, isReady] = useState(false);

  const handleOnBackdropClick = () => {
    if (disableBackdropClose) {
      return;
    }

    if (onBackdropClick) {
      onBackdropClick();
      return;
    }

    onClose?.();
  };

  useEffect(() => {
    if (open) {
      getWindow().setTimeout(() => {
        isReady(true);
      }, 10);
    }

    return () => {
      isReady(false);
    };
  }, [open]);

  if (open && typeof document !== "undefined") {
    return createPortal(
      <div
        className="fixed inset-0 z-50 flex items-center justify-center overscroll-none"
        data-testid={props?.["data-testid"]}
      >
        {hideBackdrop ? null : (
          <div
            className={[
              "absolute",
              "inset-0",
              "h-full",
              "w-full",
              "z-0",
              "bg-dark",
              "transition-opacity",
              "duration-150",
              "overscroll-none",
              ready ? `opacity-${opacity}` : "opacity-0",
              className,
            ].join(" ")}
            onClick={handleOnBackdropClick}
          />
        )}
        {children}
      </div>,
      document.querySelector("body") as HTMLElement,
    );
  }

  return null;
};

export default Modal;
